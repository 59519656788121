















































import { RoutePath } from '@/router';
import { getInstance } from '@/services';
import { VaultEncryptionSupport } from 'vaultifier';
import Vue, { PropType } from 'vue'

import { ConfigService, PACKAGE } from '../services/config-service';

interface Action {
  key: string,
  title: string,
  url: string,
  usesAuth: boolean,
  method: string,
}

interface Data {
  workingAction?: Action,
}

export default Vue.extend({
  props: {
    encryptionSupport: Object as PropType<VaultEncryptionSupport>,
    settingsActions: Array as PropType<string[]>,
    title: String as PropType<string>,
    description: String as PropType<string | undefined>,
  },
  data: (): Data => ({
    workingAction: undefined,
  }),
  methods: {
    async executeAction(action: Action) {
      this.workingAction = action;

      const { key, title, method, url, usesAuth } = action;
      const vaultifier = getInstance();
      const baseUrlPlaceholder = '{{base_url}}'

      try {
        if (url.indexOf(baseUrlPlaceholder) !== -1) {
          const vaultifierUrl = url.replace(baseUrlPlaceholder, '');

          if (method === 'POST')
            await vaultifier.post(vaultifierUrl, usesAuth);
          else if (method === 'GET')
            await vaultifier.get(vaultifierUrl, usesAuth);
          else
            throw new Error(`Invalid method for action ${key}`);
        }
        else {
          const req = await fetch(url, {
            method,
            headers: {
              'Content-Type': 'application/json',
            },
          });
          await req.text();
        }

        this.$bvModal.msgBoxOk(`The action "${title}" was executed succssfully.`);
      } catch {
        this.$bvModal.msgBoxOk(`The action "${title}" has failed.`);
      }

      this.workingAction = undefined;
    },
    goHome() {
      this.$router.push(RoutePath.MAIN_VIEW);
    }
  },
  computed: {
    version() {
      return PACKAGE.version;
    },
    encryptionIcon(): string | undefined {
      if (!this.encryptionSupport)
        return '';

      const { supportsEncryption, supportsDecryption } = this.encryptionSupport;

      if (supportsEncryption && supportsDecryption)
        return 'lock-fill'
      else if (!supportsEncryption && !supportsDecryption)
        return undefined;
      else
        return 'lock';
    },
    actions(): Action[] {
      const actionsObj = ConfigService.get('settings', 'actions');
      const arr: Action[] = []

      for (const key in actionsObj) {
        arr.push({
          usesAuth: false,
          ...actionsObj[key],
          key,
        });
      }

      return arr;
    },
    gearAnimation() {
      return this.workingAction ? 'spin' : undefined;
    },
    workingActionTitle() {
      return this.workingAction?.title;
    },
  }
})
