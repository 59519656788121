

































import { BvEvent } from 'bootstrap-vue';
import Vue, { PropType } from 'vue';
import Spinner from './Spinner.vue';
import CustomButton from '../components/Button.vue';
import InlineGroup from '../components/InlineGroup.vue';

export interface RefreshObj {
  page?: number,
}

interface Data {
  page?: number,
}

export default Vue.extend({
  props: {
    isLoading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    totalItems: Number as PropType<number | undefined>,
    currentPage: Number as PropType<number | undefined>,
    pageItems: Number as PropType<number | undefined>,
  },
  data: (): Data => ({
    page: undefined,
  }),
  components: {
    Spinner,
    CustomButton,
    InlineGroup,
  },
  created() {
    this.page = this.currentPage;
  },
  watch: {
    currentPage() {
      this.page = this.currentPage;
    }
  },
  methods: {
    changePage(evt: BvEvent, page: number) {
      this.refresh(page);
    },
    refresh(page?: number) {
      this.$emit('refresh', {
        page,
      });
    }
  }
});

