







































export interface Data {
  appKey: string,
  appSecret: string,
  scope?: string,
}

import Vue, { PropType } from 'vue'
export default Vue.extend({
  props: {
    scopes: Array as PropType<string[] | undefined>
  },
  data: (): Data => ({
    appKey: '',
    appSecret: '',
    scope: undefined,
  }),
  created() {
    if (this.scopes && this.scopes.length > 0)
      this.scope = this.scopes[0];
  },
  methods: {
    submitForm(event: Event) {
      event.preventDefault();

      this.$emit('login', {
        appKey: this.appKey,
        appSecret: this.appSecret,
        scope: this.scope,
      } as Data);
    }
  },
  computed: {
    scopeOptions() {
      return this.scopes?.map((x) => ({
        text: x,
        value: x,
      }));
    }
  }
})
